import React, { Component } from 'react';
import styles from './HeaderCarousel2.module.css';
import '../styles/App.css';
import axios from 'axios';
import constants from './constants';
import SwipeableViews from 'react-swipeable-views';
import { faChevronCircleRight, faChevronCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const styleRadioChecked = {
    border: '4px solid #84A4C5',
    boxShadow: 'rgba(255,255,255, 0.75) 0px 2px 12px 4px'
}

const styleRadioNotChecked = {
    border: ''
}

export default class HeaderCarousel1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            titles: [],
            index: 0
        }
    }

    componentDidMount() {
        axios
            .get(constants.TITLE_URL)
            .then(response => {

                this.setState({
                    titles: response.data.data
                })

            })
            .catch(error => console.log(error));
    }

    getSlides = () => {
        var data = [];
        var scontent = {};

        for (var i = 0; i < this.state.titles.length; i++) {
            var item = this.state.titles[i];
            if (item.image){
                var itemImage = "https://api.riedmann.rocks" + item.image.data.asset_url + "?key=directus-large-contain";
                
            }
            
            var style = {
                backgroundImage: `url('${itemImage}')`,
                backgroundSize: "cover",
                height: "100vh"
            }

            data.push(
                <div className={styles.slider} style={style} key={item.id}>
                    <div className={styles.sliderContent} style={scontent}>
                        {this.getLeftArrow()}
                        <div className={styles.sliderContentText}>
                            <h4 className={styles.h4}>{item.title}</h4>
                            <h2 className={styles.h2}>{item.description}</h2>
                        </div>
                        {this.getRightArrow()}
                    </div>
                </div>

            )
        }
        return data;
    }



    getRightArrow = () => {
        var color = { color: 'white' };
        if (this.state.index === this.state.titles.length - 1) {
            color = {
                color: '#dcdee244'
            }
        }


        return (
            <div className={styles.arrow}>
                <FontAwesomeIcon icon={faChevronCircleRight} onClick={this.nextSlide} style={color} />
            </div>
        )

    }

    getLeftArrow = () => {
        var color = { color: 'white' };
        if (this.state.index === 0) {
            color = {
                color: '#dcdee244'
            }
        }
        return (
            <div className={styles.arrow}>
                <FontAwesomeIcon icon={faChevronCircleLeft} onClick={this.prevSlide}  style={color} />
            </div>
        )
    }

    nextSlide = () => {
        var index = this.state.index + 1;
        if (index > this.state.titles.length - 1) {
            index = 0;
        }
        this.setState({
            index: index
        })
    }

    prevSlide = () => {
        var index = this.state.index - 1;
        if (index < 0) {
            index = this.state.titles.length - 1;
        }
        this.setState({
            index: index
        })
    }

    getRadios = () => {
        var data = [];

        for (var i = 0; i < this.state.titles.length; i++) {
            var name = "radio" + i;
            data.push(<input type="radio" id={name} key={"r" + i} />)
        }
        return data;
    }

    getLabels = () => {
        var data = [];

        for (var i = 0; i < this.state.titles.length; i++) {
            var name = "radio" + i;
            var styleLocal = this.state.index === i ? styleRadioChecked : styleRadioNotChecked;
            data.push(<label data-id={i} for={name} style={styleLocal} className={styles.label} onClick={this.setPageNumber} key={"l" + i} />)
        }
        return data;
    }

    setPageNumber = (el) => {
        var index = Number(el.target.getAttribute("data-id"));
        console.log("index:" + index);
        this.setState({
            index: index
        })

    }

    render() {
        const { index } = this.state;
        return (
            <div>
                <SwipeableViews enableMouseEvents index={index} onChangeIndex={(index => this.setState({ index: index }))}>
                    {this.getSlides()}
                </SwipeableViews>

                {this.getRadios()}
                <div className={styles.sliderPagination}>
                    {this.getLabels()}

                </div>
            </div>
        )
    }
}

