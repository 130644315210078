import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import CourseCard from './CourseCard';
import axios from 'axios';
import constants from './constants';
import styles from './CourseGrid.module.css';

class CourseGrid extends Component {

    constructor(props) {
        super(props);
        this.state = {
            courses: [],
            title: "Kurse",
            description: "Unsere Kurse"
            
        }
    }

    // loading data from instagram
    componentDidMount() {
        axios
        .get(constants.COURSE_TITLE_URL)
        .then(response => {

            this.setState({
                title: response.data.data[0].title,
                description: response.data.data[0].description
            })

        })
        .catch(error => console.log(error));
        axios
            .get(constants.COURSE_URL)
            .then(response => {

                this.setState({
                    courses: response.data.data
                })

            })
            .catch(error => console.log(error));


    }


    getGrid = () => {

        var data = [];
        for (var i = 0; i < this.state.courses.length; i++) {
            data.push(
                <Grid item xs={12} sm={6} md={6} key={this.state.courses[i].id}>
                    <CourseCard course={this.state.courses[i]} />
                </Grid>
            )

        }
        return data;
    }

    render() {

        return (
            <div className={styles.sectionSettings}>
                <h1 className={styles.sectionHeading}>{this.state.title}</h1>
                <p>{this.state.description}</p>
                <Grid container spacing={2}>
                    {this.getGrid()}
                </Grid>
            </div>
        )
    }
}

export default CourseGrid;