import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import styles1 from './TourenCard.module.css';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import AcUnitIcon from '@material-ui/icons/AcUnit';

const styles = (theme) => ({
	card: {
		maxWidth: 400
	},
	media: {
		height: 0,
		paddingTop: '56.25%' // 16:9
	},
	actions: {
		display: 'flex'
	},
	expand: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest
		})
	},
	expandOpen: {
		transform: 'rotate(180deg)'
	}
});

class InstaCard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			style: {
				bgstyle: {
					backgroundImage: `url(${this.props.image})`,
					backgroundPosition: 'center',
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'cover',
					height: '200px',
					borderTopLeftRadius: '5px',
					borderTopRightRadius: '5px'
				},

				iconStyle: {}
			}
		};
	}

	getIcon = () => {
		
		if (this.props.season === 'winter') {
			return <AcUnitIcon className={styles1.iconStyle} />;
		}
		if (this.props.season === 'summer') {
			return <WbSunnyIcon className={styles1.iconStyle} />;
		}
	};

	componentDidMount() {}

	render() {
		return (
			<div className={styles1.tourencard} style={{ position: 'relative' }}>
				<div style={this.state.style.bgstyle} />
				<div className={styles1.textcontainer}>
					<p className={styles1.tourencardheading}>{this.props.text}</p>
					<p className={styles1.tourencarddetails}>{this.props.description}</p>
				</div>
				{this.getIcon()}
			</div>
		);
	}
}

InstaCard.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(InstaCard);
