import React, { Component } from 'react'

export default class AppBar extends Component {
    constructor(props) {
        super(props);

        this.style = {
            trans: "navbar navbar-transparent",
            black: "navbar navbar-color "
        }

        this.state = {
            activeStyle: this.style.trans,
            scrollSize: 100,
            isMobile: false,
            menuClass: "collapse navbar-collapse"

        }
    }
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = (event) => {
        let scrollTop = event.srcElement.scrollingElement.scrollTop;

        if (scrollTop > this.state.scrollSize) {
            this.setState({
                activeStyle: this.style.black
            })
        } else {
            this.setState({
                activeStyle: this.style.trans
            })
        }
    }

    onNavClick = (event) => {
        event.preventDefault();
        let href = event.currentTarget.getAttribute("href");
        let el = document.getElementById(href);

        el.scrollIntoView({ block: "start", behavior: "smooth" });
        if(this.state.isMobile){
            this.setState({
                menuClass: "collapse navbar-collapse open-drawer",
                
            })
        } 
        
    }

    toggleMenu = () => {
        if (!this.state.isMobile) {
            this.setState({
                menuClass: "open-drawer-active open-drawer",
                isMobile: true
            })
        } else {
            this.setState({
                menuClass: "collapse navbar-collapse open-drawer",
                isMobile: false
            })
        }

    }

    render() {

        return (

            <div className="header" >
                <nav className={this.state.activeStyle}>
                    <div>
                        <div className="navbar-header" >
                            <div onClick={this.toggleMenu} className="menu-icon">
                                <h3>Bergführer Bregenzerwald</h3>
                            </div>
                            <div className={this.state.menuClass} id="custom-collapse">
                                <ul>
                                    <li>
                                        <a href="main" onClick={this.onNavClick} className="section-scroll">Home</a>
                                    </li>
                                  
                                    <li>
                                        <a href="touren" onClick={this.onNavClick} className="section-scroll">Touren</a>
                                    </li>
                                    <li>
                                        <a href="courses" onClick={this.onNavClick} className="section-scroll">Kurse</a>
                                    </li>
                                    <li>
                                        <a href="guides" onClick={this.onNavClick} className="section-scroll">Bergführer</a>
                                    </li>
                                    <li>
                                        <a href="contact" onClick={this.onNavClick} className="section-scroll">Kontakt</a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </nav>
            </div>

        )
    }
}

