import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import TourenCard from './TourenCard';
import axios from 'axios';
import constants from './constants';

const containerStyle = {
    color: 'white',
    textAlign: 'left',
    width: '90%',
    margin: 'auto',
    paddingBottom: '50px'
}

class GuideGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allTours: [],
            tours: [],
            skiactive: true,
            kletternactive: true,
            wandernactive: true,
        }
    }

    // http://itlabor2.htldornbirn.vol.at:7000/_/items/guides
    componentDidMount() {
        axios
        .get(constants.TOUREN_TITLE_URL)
        .then(response => {

            this.setState({
                title: response.data.data[0].title,
                description: response.data.data[0].description
            })

        })
        .catch(error => console.log(error));

        axios
            .get(constants.TOUREN_URL)
            .then(response => {
                this.setState({
                    tours: response.data.data,
                    allTours: response.data.data
                })
            })
            .catch(error => console.log(error));
    }


    getGrid = () => {

        var data = [];
        for (var i = 0; i < this.state.tours.length; i++) {
            var tour = this.state.tours[i];
            var tour_name = tour.name_of_tour;
            var description = tour.short_description;
            
            var image = tour.image != null ?"https://api.riedmann.rocks" + tour.image.data.asset_url + "?key=directus-large-contain" : null

            data.push(
                <Grid item xs={12} sm={12} md={4} key={tour.id} spacing={2}>
                    <TourenCard text={tour_name} description={description} image={image} season={tour.season} />
                </Grid>
            )

        }
        return data;
    }

    search = (e) => {
        this.state.tours.push(this.state.tours[1]);
        this.setState({ tours: this.state.tours });
    }

    filter = (typ) => {
        var filter = [];
        var sa = this.state.skiactive;
        var ka = this.state.kletternactive;
        var wa = this.state.wandernactive;

        if (typ === "skitour") {
            sa = this.state.skiactive ? false : true;
            this.setState({ skiactive: sa })
        }

        if (typ === "klettern") {
            ka = this.state.kletternactive ? false : true;
            this.setState({ kletternactive: ka });
        }
        if (typ === "wandern") {
            wa = this.state.wandernactive ? false : true;
            this.setState({ wandernactive: wa })
        }

        if (sa) {
            filter.push("skitour");
        }
        if (ka) {
            filter.push("klettern");
        }
        if (wa) {
            filter.push("wandern");
        }
        this.filterData(filter);
    }

    filterData = (filter) => {
        var passedTest = [];
        for (var i = 0; i < this.state.allTours.length; i++) {
            if (filter.includes(this.state.allTours[i].season))
                passedTest.push(this.state.allTours[i]);
        }

        this.setState({ tours: passedTest });

    }
    render() {
        return (
            <div style={containerStyle}>
                <h1 style={{marginBottom:'9px'}}>{this.state.title}</h1>
                <p>{this.state.description}</p>
               

                <Grid container spacing={2}>
                    {this.getGrid()}
                </Grid>
            </div>


        )
    }
}

export default GuideGrid;