import React, { Component } from 'react';
import styles from './TourenApp.module.css';


class TourenApp extends Component {

    render() {
        return (
            <div class={styles.main} style={{ backgroundImage: "url('images/folder.jpg')" }}>
                <img alt="logo" src="./images/logostf.svg" width="40%" />
                <div class={styles.text}>
                    <p>Der Skitourenführer Bregenzerwald ist auch als App verfügbar! Über 120 wunderschöne Touren. Von Genußtouren
                    bis zu echten Klassikern!
                     </p>
                    <div>
                        <img alt="qrcode of stores" src="./images/qrcode.svg" width="150px"/>
                    </div>
                    <div class={styles.stores}>
                        <a href="https://play.google.com/store/apps/details?id=rocks.riedmann.skitourenfuehrer"  without rel="noreferrer" target="_blank"><img alt="playstore" src="./images/playstore.svg" width="150px" class={styles.stores} /></a>
                        <a href="https://apps.apple.com/us/app/skitouren-bregenzerwald/id1544465732"   without rel="noreferrer" target="_blank"><img alt="appstore" src="./images/appstore.svg"  width="150px"/></a>
                    </div>


                </div>
            </div>
        );
    }
}

export default TourenApp;