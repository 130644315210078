import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import Datenschutz from './components/Datenschutz';
import Loader from './Loader.js';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom'
import { Switch, Route } from 'react-router-dom'
import TourenApp from './components/TourenApp';




ReactDOM.render(
    <BrowserRouter>
        <Switch>
            <Route exact path='/Datenschutz' component={Datenschutz}/>
            <Route exact path='/' component={Loader}/>
            <Route path="/tourenapp" component={TourenApp}/>
            
            {/* both /roster and /roster/:number begin with /roster */}
          
        </Switch>
    </BrowserRouter>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
