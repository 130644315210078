import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import GuideCard from './GuideCard';
import axios from 'axios';
import constants from './constants';
import styles from './GuideGrid.module.css';


class GuideGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            guides: []
        }
    }

    // http://itlabor2.htldornbirn.vol.at:7000/_/items/guides
    componentDidMount() {
        axios
            .get(constants.GUIDE_TITLE_URL)
            .then(response => {

                this.setState({
                    title: response.data.data[0].title,
                    description: response.data.data[0].description
                })

            })
            .catch(error => console.log(error));

        axios
            .get(constants.GUIDE_URL)
            .then(response => {
                this.setState({
                    guides: response.data.data
                })
            })
            .catch(error => console.log(error));
    }


    getGrid = () => {
        var data = [];
        for (var i = 0; i < this.state.guides.length; i++) {
            var guide = this.state.guides[i];
            var guide_name = guide.name_of_guide;
            
            var image = guide.image != null ? "https://api.riedmann.rocks" + guide.image.data.asset_url + "?key=directus-large-contain" : null
            data.push(
                <Grid item xs={12} sm={6} md={6} key={guide.id}>
                    <GuideCard text={guide_name} description={guide.description} mountains={guide.favorite_mountains} image={image} />
                </Grid>
            )
        }
        return data;
    }

    render() {
        return (
            <div className={styles.background}>
                <h1>{this.state.title}</h1>
                <p>{this.state.description}</p>
                <div className={styles.guidegrid}>
                    <Grid container spacing={2}>
                        {this.getGrid()}
                    </Grid>
                </div>

            </div>
        )
    }
}

export default GuideGrid;