import React, { Component } from 'react';
import '../styles/App.css';
import Section from './Section';
import AppBar from './AppBar';
import TopPadding from './TopPadding';
import GuideGrid from './GuideGrid';
import TourenGrid from './TourenGrid';
import CourseGrid from './CourseGrid';
import HeaderCarousel2 from './HeaderCarousel.2';
import axios from 'axios';
import constants from './constants';
import { Link } from 'react-router-dom';


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      titles: []
    }
  }


  componentDidMount() {
    
    axios
      .get(constants.TITLE_URL)
      .then(response => {
        
        this.setState({
          titles: response.data.data
        })

      })
      .catch(error => console.log(error));
  }

  render() {
    return (
      <div>
        <AppBar active="0" />

        <Section id="main" bgimage="./images/berg.jpg" height="100vh" style={{ textAlign: 'center' }} >
          <HeaderCarousel2 />

        </Section>

        <Section id="touren" bgcolor="#118ab2">
          <TopPadding padding="90">
            <TourenGrid />
          </TopPadding>
        </Section>
        <Section id="courses" bgcolor="#16679a">
          <CourseGrid />
        </Section>

        <Section id="guides" bgcolor="#305679">
          <TopPadding padding="90">
            <GuideGrid />

          </TopPadding>
        </Section>
        <Section id="contact" bgcolor="black" padding="90">
          <div className="container heading">
            <div style={{ textAlign: 'center', color: 'white' }}>
              <h1>Kontakt</h1>
              <p><a href="mailto:rainer.kempfberg@gmail.com">rainer.kempfberg@gmail.com</a></p>
            </div>
          </div>
        </Section>
        <div className="footer">
          <a href="/AGB_Kempf.pdf">AGB</a>
          <Link to="Datenschutz">Impressum</Link>
        </div>

      </div>
    );
  }
}

export default App;
