import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import styles from './CourseCard.module.css';

class CourseCard extends Component {
    constructor(props) {
        super(props);

        var image = this.props.course.image != null ? "https://api.riedmann.rocks" + this.props.course.image.data.asset_url + "?key=directus-large-contain" : null
        this.state = {
            style: {
                bgstyle: {
                    backgroundImage: `url(${image})`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    height: '100%',
                    width: '100%'
                },
                text: {
                    position: 'absolute',
                    color: 'white'
                }

            }
        }
    }



    render() {

        return (
            <div className={styles.courseContainer} >
                <Grid container style={{ height: '100%' }} spacing={0}>
                    <Grid item xs={12} sm={12} md={4} key="1">
                        <div style={this.state.style.bgstyle}>

                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} key="2">
                        <div className={styles.courseText}>
                            <ul>
                                <li>{this.props.course.name}</li>
                                <li>{this.props.course.amount_of_people}</li>
                                <li>{this.props.course.duration}</li>
                            </ul>
                            <div className={styles.courseDetail}>
                                {this.props.course.description}
                            </div>
                        </div>
                    </Grid>

                </Grid>

            </div>


        )
    }
}



export default CourseCard;