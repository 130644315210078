const base_url = "https://api.riedmann.rocks/bf/";

module.exports ={
    INSTA_URL: "https://api.instagram.com/v1/users/self/media/recent/?access_token=13730163356.ce165e2.bcf2713f4c6146a88413c2032f6a4853",
    BASE_URL: base_url,
    GUIDE_URL: base_url + "items/guides?fields=*.data.full_url&status=published",
    GUIDE_TITLE_URL: base_url + "items/guides_title",
    TOUREN_URL: base_url + "items/tour?fields=*.*.*&status=published",
    TOUREN_TITLE_URL: base_url + "items/tour_title",
    COURSE_URL: base_url + "items/courses?fields=*.*.*&status=published",
    COURSE_TITLE_URL:  base_url + "items/courses_title",
    TITLE_URL: base_url + "items/title?fields=*.*.*&status=published",
    BLOG_TITLE_URL: base_url + "items/blog_title"
}